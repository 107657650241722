const a = {
  less: "less than a minute read",
  default: "min read"
}, r = {
  less: "moins d'une minute de lecture",
  default: "min de lecture"
}, d = {
  less: "menos de un minuto leyendo",
  default: "min de lectura"
}, o = {
  less: "小于一分钟",
  default: "分钟"
}, l = {
  less: "1分未満の読み取り",
  default: "最小読み取り"
}, c = {
  less: "weniger als eine Minute Lesezeit",
  default: "Min. Lesezeit"
}, m = {
  less: "menos de um minuto de leitura",
  default: "minutos de leitura"
}, f = {
  less: "bir dakikadan az okuma süresi",
  default: "dakika okuma süresi"
}, p = {
  less: "timp de citire mai puțin de un minut",
  default: "min. timp de lectură"
}, g = {
  en: a,
  fr: r,
  es: d,
  cn: o,
  ja: l,
  de: c,
  "pt-br": m,
  tr: f,
  ro: p
}, L = [
  "en",
  "fr",
  "es",
  "cn",
  "ja",
  "de",
  "pt-br",
  "tr",
  "ro"
], b = (e) => e.match(/[\w|\d|\s|,|.|\u00C0-\u024F|\u4E00-\u9FA5|\u3041-\u309F]+/giu) ?? [], h = (e) => b(e).reduce((t, s) => t + (s.trim().length ? s.trim().split(/\s+/u).length : 0), 0), i = (e) => e < 1 + Number.EPSILON, k = (e, t = "en") => g[t][i(e) ? "less" : "default"], F = (e, t = 300, s = "en") => {
  const u = h(e), n = Number(Math.round(u / t).toFixed(2));
  return {
    minutes: n,
    words: u,
    text: `${i(n) ? "" : `${n} `}${k(n, s)}`
  };
};
export {
  F as readingTime,
  L as supportedLanguages
};
